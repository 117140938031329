import React from "react";
import LoadingGif from "assets/images/loading.gif";
import { Spin as AntSpin, SpinProps } from "antd";
import classNames from "classnames";
import "./index.css";

interface IProps extends SpinProps {}

const Spin: React.FC<IProps> = ({ rootClassName, spinning, children }) => {
    return (
        <AntSpin
            rootClassName={classNames("custom-spin", rootClassName)}
            indicator={
                <div className="spinning-container">
                    <img src={LoadingGif} alt="Spinning" />
                </div>
            }
            spinning={spinning}
        >
            {children}
        </AntSpin>
    );
};

export default Spin;
