import { LocationIcon } from "assets/icons";
import { Outlet } from "react-router-dom";
import useAppSelector from "hooks/useAppSelector";
import Spin from "components/Spin/Spin";

const AuthLayout = () => {
    const merchant = useAppSelector((state) => state.merchant.merchant);

    return (
        <Spin spinning={!merchant}>
            <div className="login-container w-full min-h-[100svh] h-[100svh] flex flex-col bg-gradient-to-r from-secondary to-primary">
                <div className="w-full h-auto">
                    <div className="pt-13.25 pb-12.25 text-center lg:pt-18.5 lg:pb-11.75">
                        <h5 className="font-normal text-5 leading-6 text-light lg:text-6 lg:leading-7.25">
                            Welcome to
                        </h5>
                        <h3
                            className="font-bold pt-2.5 text-8 leading-9.75 text-light lg:text-10.5 lg:leading-12.75 lg:pt-3"
                            data-testid="businessName"
                        >
                            {merchant?.businessName || "Booking Appointment Form"}
                        </h3>
                        <div className="pt-2 flex gap-1.25 items-start justify-center px-2">
                            <div className="flex items-center gap-x-1">
                                <LocationIcon height={18} />
                                <span data-cy="merchant-address" className="text-sm leading-4.5 font-normal text-light">
                                    {merchant?.addressFull}
                                </span>
                            </div>
                        </div>
                        <p className="font-norma pt-3.75 text-3.25 leading-4 text-light lg:text-4 lg:leading-4.75 lg:pt-1.5">
                            Book your appointment with a few simple steps!
                        </p>
                    </div>
                </div>
                <Outlet />
            </div>
        </Spin>
    );
};

export default AuthLayout;
