class CustomError extends Error {
    private statusCode: number;
    constructor(message: string, statusCode: number) {
        super(message);
        this.statusCode = statusCode;
    }

    public getStatusCode() {
        return this.statusCode;
    }
}

export default CustomError;
