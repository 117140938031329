import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import store from "./store";
import { Provider } from "react-redux";
import Theme from "theme";
import { ConfigProvider, App as AntApp } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider theme={Theme}>
                <AntApp message={{ maxCount: 1 }} rootClassName="w-full h-full">
                    <App />
                </AntApp>
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
);

declare global {
    interface Window {
        Cypress?: any;
        store?: any;
    }
}

if (typeof window !== "undefined" && window.Cypress) {
    window.store = store;
}

reportWebVitals();
