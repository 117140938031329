import MainLayout from "layout/MainLayout";
import LoadingFallback from "components/LoadingFallback";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AuthLayout from "layout/AuthLayout";

const Login = lazy(() => import("pages/Login"));
const SignUp = lazy(() => import("pages/SignUp"));
const Verify = lazy(() => import("pages/Verify"));
const NotFound = lazy(() => import("pages/NotFound"));
const WelcomeBack = lazy(() => import("pages/WelcomeBack"));
const Home = lazy(() => import("pages/Home"));
const Histories = lazy(() => import("pages/Histories"));
const Success = lazy(() => import("pages/Success"));

const PRIVATE_ROUTES = [
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/histories",
        element: <Histories />,
    },
];

export default function routes() {
    return (
        <React.Suspense fallback={<LoadingFallback />}>
            <Routes>
                <Route element={<AuthLayout />}>
                    <Route path="/login" element={<Login />} />
                    <Route path="/verify" element={<Verify />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/welcome-back" element={<WelcomeBack />} />
                    <Route path="/success" element={<Success />} />
                </Route>
                <Route path="/" element={<MainLayout />}>
                    {PRIVATE_ROUTES.map((item) => {
                        return (
                            <Route
                                key={item.path}
                                path={item.path}
                                element={<React.Suspense fallback={<LoadingFallback />}>{item.element}</React.Suspense>}
                            />
                        );
                    })}
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </React.Suspense>
    );
}
