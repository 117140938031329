import { MappingAlgorithm } from "antd";
import { ComponentToken } from "antd/es/button/style";
import { AliasToken } from "antd/es/theme/internal";

const Button: Partial<ComponentToken> &
    Partial<AliasToken> & {
        algorithm?: boolean | MappingAlgorithm | MappingAlgorithm[];
    } = {
    colorPrimary: "#0078AA",
    algorithm: true,
    lineHeight: 18 / 14,
    controlHeight: 40,
    marginXS: 5,
    borderRadius: 6,
    colorBgContainerDisabled: "#0078AA",
};

export default Button;
