import CancelIcon from "assets/images/cencle.svg";
import CheckIcon from "assets/images/check.svg";
import ConfirmIcon from "assets/images/comfirm.svg";
import NoShowIcon from "assets/images/noshow.svg";
import PaidIcon from "assets/images/paid.svg";
import RefundIcon from "assets/images/refund.svg";
import UnconfirmIcon from "assets/images/uncomfirm.svg";
import VoidIcon from "assets/images/void.svg";
import WaitingIcon from "assets/images/waiting.svg";
import IBlockTime from "interfaces/IBlockTime";
import moment from "moment";
import { toast } from "react-toastify";
import ICart from "interfaces/ICart";
import IAvailableStaff from "interfaces/IStaffAvailable";
import axios, { AxiosError } from "axios";
import CustomError from "providers/CustomError";
import { HTTP_STATUS_CODES } from "configs";

interface Map {
    [key: string]: {
        color: string;
    };
}

const navigateToNotFoundPage = () => {
    const params = new URLSearchParams(window.location.search);
    const merchant_id = params.get("merchant_id");
    if (!window.location.href.includes("not-found")) {
        window.location.href = `/not-found?merchant_id=${merchant_id}`;
    }
};

const navigateToLoginPage = () => {
    const params = new URLSearchParams(window.location.search);
    const merchant_id = params.get("merchant_id");
    if (!window.location.href.includes("login")) {
        window.location.href = `/login?merchant_id=${merchant_id}`;
    } else {
        window.location.href = `/not-found?merchant_id=${merchant_id}`;
    }
};

const GSM_CODE_POINTS = new Set([
    0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037,
    0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052,
    0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e,
    0x006f, 0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
    0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc, 0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8,
    0x03a9, 0x20ac,
]);

export enum ENUM_ANONYMOUS_STAFFS_ID {
    WAITING_LIST = -1,
    ANY_STAFF = 0,
}

export function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function handleResponseError(error: AxiosError | CustomError) {
    if ((error instanceof CustomError && error.getStatusCode() === HTTP_STATUS_CODES.UNAUTHORIZED) || (axios.isAxiosError(error) && error.status === HTTP_STATUS_CODES.UNAUTHORIZED)) {
        navigateToLoginPage();
    } else if ((error instanceof CustomError && error.getStatusCode() === HTTP_STATUS_CODES.NOTFOUND) || (axios.isAxiosError(error) && error.status === HTTP_STATUS_CODES.NOTFOUND)) {
        navigateToNotFoundPage();
    } else {
        if (error.message) {
            showToastError(error.message);
        } else if (typeof error === "string") {
            showToastError(error);
        } else {
            showToastError("Unknow sever error!");
        }
    }
}

export function convertMinsToHrsMins(minutes: number): string {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;

    const hourStr = h;
    const minutesStr = m < 10 ? "0" + m : m;

    if (h <= 0) {
        return `${minutesStr} min`;
    }

    return `${hourStr} hour ${minutesStr} min`;
}

export function formatMoney(currency: string): string {
    const money = currency.replace(/[^0-9.-]+/g, "");
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    return money ? formatter.format(Number(money)).replace("$", "$ ") : "";
}

export function isNumeric(value: string): boolean {
    return /^-?\d+$/.test(value);
}

export function converStrToNumber(value: string) {
    const format = value.replace(/[^0-9.-]+/g, "");
    return Number(format);
}

export const showDateTimeCart = (cart: ICart) => {
    if (cart?.date && cart?.time?.trim()) {
        if (cart?.staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST) {
            return `${moment(`${moment(cart.date).format(FORMAT_DATE)} ${moment(cart.time, "HH:mm").format(FORMAT_TIME)}`).format(FORMAT_DATE)}`;
        } else {
            return `${moment(`${moment(cart.date).format(FORMAT_DATE)} ${moment(cart.time, "HH:mm").format(FORMAT_TIME)}`).format(FORMAT_DATE_TIME)}`;
        }
    }
};

export function getDepositType(depositPercent?: string, depositFixedAmount?: string) {
    if (!depositPercent || !depositFixedAmount) {
        return "";
    }

    if (parseFloat(depositFixedAmount) === 0 && parseFloat(depositPercent) !== 0) {
        return ` (${depositPercent}%)`;
    }

    if (parseFloat(depositPercent) === 0 && parseFloat(depositFixedAmount) !== 0) {
        return ` ($${depositFixedAmount})`;
    }
    return "";
}

export function statusShow(text: string) {
    const status = text.toLowerCase();

    const STATUS_SETTING: Map = {
        cancel: {
            color: "bg-[#EA4335]",
        },
        unconfirm: {
            color: "bg-[#FBA705]",
        },
        paid: {
            color: "bg-[#2EBE03]",
        },
        complete: {
            color: "bg-[#DFDFDF]",
        },
        waiting: {
            color: "bg-[#82A2C1]",
        },
        refund: {
            color: "bg-[#0CAEAE]",
        },
        processing: {
            color: "bg-[#FEDC32]",
        },
        "transaction fail": {
            color: "bg-[#FEDC32]",
        },
        checkin: {
            color: "bg-[#19A9EC]",
        },
        incomplete: {
            color: "bg-[#19A9EC]",
        },
        "no show": {
            color: "bg-[#767676]",
        },
        pending: {
            color: "bg-[#FFF3DD]",
        },
        canceled: {
            color: "bg-[#EA4335]",
        },
        shipped: {
            color: "bg-[#19A9EC]",
        },
        return: {
            color: "bg-[#B73B36]",
        },
        void: {
            color: "bg-[#EA4335]",
        },
        confirm: {
            color: "bg-[#00ABC5]",
        },
        wait: {
            color: "bg-[#82A2C1]",
        },
    };

    return STATUS_SETTING[status] ? `${STATUS_SETTING[status].color}` : "bg-black";
}

export function handleCheckStatus(text: string) {
    switch (text) {
        case "paid":
            return PaidIcon;
        case "processing":
            return PaidIcon;
        case "checkin":
            return CheckIcon;
        case "waiting":
            return WaitingIcon;
        case "cancel":
            return CancelIcon;
        case "confirm":
            return ConfirmIcon;
        case "void":
            return VoidIcon;
        case "refund":
            return RefundIcon;
        case "unconfirm":
            return PaidIcon;
        case "no show":
            return NoShowIcon;
        default:
            return UnconfirmIcon;
    }
}

export const showToastError = (message: string) => {
    toast.error(message, {
        toastId: message,
    });
};

export const showToastSuccess = (message: string) => {
    toast.success(message, {
        toastId: message,
    });
};

export const showToastWarning = (message: string) => {
    toast.warning(message, {
        toastId: message,
    });
};

export function getTimeAvaible(staff_available_time: IBlockTime[]) {
    const time12PM = `${moment().format("YYYY-MM-DD")}T12:00:00`;
    const time05PM = `${moment().format("YYYY-MM-DD")}T17:00:00`;

    const morning = staff_available_time?.filter((obj) => {
        const timeFilter = `${moment().format("YYYY-MM-DD")}T${moment(obj.time, ["h:mm A"]).format("HH:mm:ss")}`;
        return moment(timeFilter).isSameOrBefore(time12PM);
    });

    const afternoon = staff_available_time?.filter((obj) => {
        const timeFilter = `${moment().format("YYYY-MM-DD")}T${moment(obj.time, ["h:mm A"]).format("HH:mm:ss")}`;
        return moment(timeFilter).isAfter(time12PM) && moment(timeFilter).isBefore(time05PM);
    });

    const evening = staff_available_time?.filter((obj) => {
        const timeFilter = `${moment().format("YYYY-MM-DD")}T${moment(obj.time, ["h:mm A"]).format("HH:mm:ss")}`;
        return moment(timeFilter).isSameOrAfter(time05PM);
    });

    return {
        morning,
        afternoon,
        evening,
    };
}

export const showDateTime = (cart: ICart, isShowDefault = true) => {
    if (cart?.date && cart?.time?.trim()) {
        return `${moment(`${moment(cart.date).format(FORMAT_DATE)} ${moment(cart.time, "HH:mm").format("hh:mm A")}`).format(FORMAT_DATE_TIME)}`;
    }
    return isShowDefault ? "Select Date/Time" : "";
};

export const checkCanEditStaff = (cart: ICart[], isEdit = false) => {
    if (isEdit && cart && cart.length === 1 && cart[0] && (cart[0].staff?.staffId === 0 || cart[0].staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST)) return true;

    if (cart[0] && cart[0].staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST) {
        return false;
    }

    return true;
};

export const checkCanEditTime = (cart: ICart[], isEdit = false, cartItem: ICart | null) => {
    if (isEdit === false && cartItem && cartItem.staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST) return false;

    if (isEdit && cartItem?.staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST) return false;

    if (isEdit && cart && cart.length === 1 && cartItem && cartItem.staff?.staffId !== -1) return true;

    if (cart[0] && cart[0].staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST) {
        return false;
    }

    return true;
};

export const ignoreNotGSMCharacter = (message: string) => {
    const data = [];
    for (const s of message) {
        const codePoint = s.codePointAt(0);
        if (codePoint && GSM_CODE_POINTS.has(codePoint)) {
            data.push(s);
        }
    }
    return data.join("");
};

export const formatMessageText = (messages = "") => {
    if (!messages) {
        return "";
    }

    const stringArray = messages?.split("\n");
    if (!Array.isArray(stringArray) || stringArray?.length < 1) {
        return messages;
    }

    const textWithoutBreakLine = stringArray?.filter((item) => item !== "" && item?.trim() !== "").join("\n");

    return textWithoutBreakLine;
};

export const getLastDateTime = (cart: ICart[]) => {
    let date = new Date();
    let time = "";
    if (cart && cart.length) {
        const item = cart[cart.length - 1];
        const durations = getTotalDurationItem(item);

        date = moment(`${moment(item.date).format("YYYY/MM/DD")} ${moment(item.time, "HH:mm").format("hh:mm A")}`)
            .add(durations, "minutes")
            .toDate();
        time = moment(item.time, "HH:mm A").add(durations, "minutes").format("HH:mm A");
    }
    return {
        date,
        time,
    };
};

export const updateDateTime = (carts: ICart[], startTime: Date | null) => {
    const newCarts = [...carts];
    newCarts.forEach((item, index) => {
        if (index !== 0) {
            const { date, time } = getLastDateTime([carts[index - 1]]);
            item.date = date;
            item.time = time;
        } else if (startTime) {
            item.date = moment(startTime).toDate();
            item.time = moment(startTime).format("HH:mm A");
        }
    });
    return newCarts;
};

export const getTotalDurationItem = (cart: ICart) => {
    let total = cart.service?.totalDuration || 0;
    cart.extras?.forEach((ex) => {
        total += ex.duration;
    });
    return total;
};

export const showTime = (cart: ICart[], cartItem: ICart | null, isSameStartTimeForServices: boolean, isEditCart: boolean) => {
    let time = cart[0].time;
    if (cartItem?.staff && isSameStartTimeForServices && !isEditCart) {
        cart.forEach((item) => {
            if (item?.staff?.staffId === cartItem?.staff?.staffId) {
                const durations = getTotalDurationItem(item);
                time = moment(time, "HH:mm A").add(durations, "minutes").format("HH:mm A");
            }
        });
        if (isSameStartTimeForServices && cartItem.staff?.staffId === ENUM_ANONYMOUS_STAFFS_ID.ANY_STAFF) {
            time = cart[0].time;
        }
    }
    if (isEditCart && isSameStartTimeForServices && cartItem?.staff?.staffId !== ENUM_ANONYMOUS_STAFFS_ID.ANY_STAFF) {
        const data = cart.filter((item) => item?.staff?.staffId === cartItem?.staff?.staffId);
        if (data.length > 1) {
            for (let i = 0; i < data.length; i++) {
                if (data[i]?.cartId === cartItem?.cartId) {
                    break;
                }
                const durations = getTotalDurationItem(data[i]);
                time = moment(time, "HH:mm A").add(durations, "minutes").format("HH:mm A");
            }
        } else {
            time = cart.length > 1 ? cart[0].time : cartItem?.time;
        }
    }
    return time;
};

export const getExtraIds = (cartItem: ICart | null) => {
    if (cartItem && cartItem?.extras && cartItem?.extras?.length > 0) {
        const extra = cartItem.extras.map((item) => {
            return item.extraId;
        });
        return extra.toString();
    }
    return "";
};

export const checkAvailableStaff = (data: IAvailableStaff[], staffId: number) => {
    if (data.length > 0) {
        if (staffId === ENUM_ANONYMOUS_STAFFS_ID.ANY_STAFF) return true;
        const value = data.filter((item) => item.staffId === staffId);
        if (value.length > 0) return true;
    }
    return false;
};

export const getDateTime = (cartItem: ICart | null, cart: ICart[], isSameStartTimeForServices: boolean, isEditCart: boolean) => {
    if (cartItem) {
        if (isSameStartTimeForServices && cart?.length > 0 && cartItem.staff?.staffId !== ENUM_ANONYMOUS_STAFFS_ID.WAITING_LIST && cartItem.staff?.staffId !== ENUM_ANONYMOUS_STAFFS_ID.ANY_STAFF) {
            return `${moment(cart[0].date).format("L")} ${moment(showTime(cart, cartItem, isSameStartTimeForServices, isEditCart), "HH:mm").format("hh:mm A")}`;
        } else {
            return `${moment(`${moment(cartItem.date).format("L")} ${moment(cartItem.time, "HH:mm").format("hh:mm A")}`).format("L hh:mm A")}`;
        }
    } else {
        return "";
    }
};

export const delayTime = (delay = 500) => {
    return new Promise<void>((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, delay);
    });
};

export const FORMAT_DATE = "MM/DD/YYYY";
export const FORMAT_DATE_TIME = "MM/DD/YYYY hh:mm A";
export const FORMAT_TIME = "hh:mm A";
