import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const LoadingFallback = () => {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
        </div>
    );
};

export default LoadingFallback;
