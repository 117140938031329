import * as React from "react";
import { SVGProps } from "react";

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={10} fill="none" {...props}>
        <path
            fill={props?.fill ? props?.fill : "#fff"}
            d="M5.64.27c.18.178.267.395.26.65a.922.922 0 0 1-.282.65l-2.54 2.533h10.023c.255 0 .468.086.641.258A.863.863 0 0 1 14 5a.867.867 0 0 1-.259.64.867.867 0 0 1-.64.257H3.08L5.64 8.453c.18.18.27.392.27.64a.87.87 0 0 1-.27.638A.876.876 0 0 1 5 10a.873.873 0 0 1-.64-.27L.247 5.629a.792.792 0 0 1-.191-.292A.969.969 0 0 1 0 5c0-.12.019-.232.057-.336a.796.796 0 0 1 .19-.292L4.382.247A.839.839 0 0 1 5 0c.246 0 .46.09.64.27Z"
        />
    </svg>
);
export default ArrowLeftIcon;
