import { ThemeConfig } from "antd/es/config-provider";
import Button from "./overrides/Button";
import Checkbox from "./overrides/Checkbox";

const Theme: ThemeConfig = {
    components: {
        Button: Button,
        Checkbox: Checkbox,
        Modal: {
            fontFamily: `Inter, sans-serif`,
            algorithm: true,
        },
    },
};

export default Theme;
