import * as React from "react";
import { SVGProps } from "react";

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} fill="none" {...props}>
        <path
            stroke={props.stroke ? props.stroke : "#fff"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M11.111 10.4c.9.383 1.445.884 1.445 1.433C12.556 13.03 9.969 14 6.778 14 3.587 14 1 13.03 1 11.833c0-.549.545-1.051 1.444-1.433"
        />
        <path
            stroke={props.stroke ? props.stroke : "#fff"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M10.389 4.932c0 2.234-3.611 6.18-3.611 6.18s-3.611-3.946-3.611-6.18C3.167 2.699 4.784 1 6.777 1c1.995 0 3.612 1.699 3.612 3.932Z"
        />
        <path
            stroke={props.stroke ? props.stroke : "#fff"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M6.778 5.333a.722.722 0 1 0 0-1.444.722.722 0 0 0 0 1.444Z"
        />
    </svg>
);
export default LocationIcon;
