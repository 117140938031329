const HTTP_STATUS_CODES = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    NOTFOUND: 404,
};

const NAILSOFT_URL = `https://nailsoft.com/`;

const API_BASE_URL = process.env.REACT_APP_URL_API;

const APP_VERSION = process.env.REACT_APP_VERSION;

const TIME_BUILD = "82a702f9-5396-46f8-866e-28186b32da9e";

const NMI_TOKEN = process.env.REACT_APP_COLLECT_JS_TOKENIZATION_KEY || "";

const DOWNLOAD_BBNS_APP_URL = process.env.REACT_APP_DOWNLOAD_BBNS_APP_URL || "";

const NAILSOFT_POLICY_URL = process.env.REACT_APP_NAILSOFT_POLICY_URL || "";

const OTP_LENGTH = 4;

const RESEND_OTP_TIMER = 60;

enum PAYMENT_GATE_WAYS {
    ARISE = "arise",
    NMI = "nmi",
}

export { HTTP_STATUS_CODES, NAILSOFT_URL, API_BASE_URL, APP_VERSION, PAYMENT_GATE_WAYS, TIME_BUILD, NMI_TOKEN, DOWNLOAD_BBNS_APP_URL, OTP_LENGTH, RESEND_OTP_TIMER, NAILSOFT_POLICY_URL };
