import * as React from "react";
import { SVGProps } from "react";

const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={72} height={72} fill="none" {...props}>
        <path
            stroke="#0F9D58"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M70 32.891v3.128A34 34 0 1 1 49.838 4.943"
        />
        <path
            stroke="#0F9D58"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M70 8.82 36 42.852l-10.2-10.2"
        />
    </svg>
);
export default SuccessIcon;
