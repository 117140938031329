export const SELECT_SERVICE = "cart/selectService";
export const SELECT_STAFF = "cart/selectStaff";
export const ADD_EXTRA = "cart/addExtra";
export const REMOVE_EXTRA = "cart/removeExtra";
export const STAFF_TIME = "cart/staffTime";
export const SELECT_DATE_TIME = "cart/dateTime";
export const ADD_ITEM_TO_CART = "cart/addItem";
export const REMOVE_ITEM_IN_CART = "cart/removeItem";
export const EDIT_ITEM_IN_CART = "cart/editItemInCart";
export const SELECT_ITEM_IN_CART = "cart/selectItemInCart";
export const CLEAR_CART = "cart/clearCart";
